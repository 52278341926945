import { graphql } from 'gatsby';
import React from 'react';

import { Home } from '../components/Home/Home';
import { Root } from '../components/Root';
import { ProjectType } from '../types';

export const query = graphql`
  query homepage {
    projects: allSanityProject(
      limit: 2
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      edges {
        node {
          Year
          Name
          Tags
          Client
          VideoUrl
          Slug
          Thumb {
            asset {
              fluid(maxWidth: 1200) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
    sanityShowreelVideo {
      VideoUrl
    }
  }
`;

export default ({ data }) => {
  const videoUrl = data.sanityShowreelVideo.VideoUrl;
  const theProjects: ProjectType[] = data.projects.edges.map(item => {
    return item.node;
  });

  return (
    <Root>
      <Home projects={theProjects} showreelUrl={videoUrl} />
    </Root>
  );
};
