export function calcCoverSize(
  containerWidth: number,
  containerHeight: number,
  objectAspectRatio: number
) {
  const containerAspect = containerWidth / containerHeight;
  const width =
    containerAspect > objectAspectRatio
      ? containerWidth
      : containerHeight * objectAspectRatio;

  const height =
    containerAspect > objectAspectRatio
      ? containerWidth / objectAspectRatio
      : containerHeight;

  return {
    width,
    height
  };
}
