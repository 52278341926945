import React from 'react';
import styled from '@emotion/styled/macro';
import { UnstyledLink } from '../UI/StyledLink';
import GreaterThanLogo from './greaterThan.svg';
import { Text } from '../UI/Text';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { css } from '@emotion/core';
import { Breakpoint } from '../../types';


export function AllProjectsLink() {
    return (
        <AllProjectsContainer>
            <AllProjects mode="subtitle">
                <UnstyledLink to="/projects">ALL PROJECTS
              <GreaterThan src={GreaterThanLogo} alt="More" />
            </UnstyledLink>
            </AllProjects>

        </AllProjectsContainer>
    );
}

const AllProjectsContainer = styled.div`
  display: inline-flex;
  margin-left: 1rem;
  align-items: center;
  
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      display: none;
    `
  )}
`;

const AllProjects = styled(Text)`
  text-transform: uppercase;
  font-size: 1.125rem;
`;

const GreaterThan = styled.img`
  margin-left: 0.7rem;
  width: 0.5rem;
  padding-top: 4px;
  `;
